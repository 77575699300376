import styled from '@emotion/styled'
import type { OperatorUiElement } from '@orus.eu/dimensions'
import { colorTokens, FlexSpacedRow, spacing, Text } from '@orus.eu/pharaoh'
import { trpcReact } from '../../../../client'
import { ChipOrganizations } from '../../../organisms/chip-organisations'
import { ifStateProxy } from '../if-state-proxy'

export const OperatorUiElementBlock = ifStateProxy<OperatorUiElement>(function OperatorUiElementBlock({
  subscriptionId,
}) {
  const operatorEmail = trpcReact.subscriptions.getOperatorEmailForSubscription.useQuery(subscriptionId)
  const organizationTechnicalName =
    trpcReact.organizations.getOrganizationTechnicalNameBySubscriptionId.useQuery(subscriptionId)

  if (!operatorEmail.data || !organizationTechnicalName.data) return <></>

  return (
    <CreatedByRow>
      <Text variant="body2" color={colorTokens['color-text-base-basic']}>
        {operatorEmail.data}
      </Text>
      <ChipOrganizations organizations={[organizationTechnicalName.data]} />
    </CreatedByRow>
  )
})

const CreatedByRow = styled(FlexSpacedRow)`
  place-content: center flex-start;
  display: flex;
  padding: ${spacing['50']};
  align-items: center;
  gap: 16px ${spacing['50']};
  flex-wrap: wrap;
  background: ${colorTokens['color-bg-base-normal']};
  border: 1px solid ${colorTokens['color-stroke-base']};
  border-radius: ${spacing['30']};
`
