import type { ContactBannerSubscriptionUiElement } from '@orus.eu/dimensions'
import { ContactBanner } from '@orus.eu/pharaoh/src/components/features/universal-subscription'
import { getSupportContactDetails } from '../common/get-support-contact-details'
import { ifStateProxy } from '../if-state-proxy'

export const ContactBannerSubscriptionUiElementBlock = ifStateProxy<ContactBannerSubscriptionUiElement>(
  function ContactBannerSubscriptionUiElementBlock({ stateProxy, uiElement, organization }) {
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const { isOrusContact, helpContactDetails } = getSupportContactDetails(organization, operatingZone)

    return <ContactBanner variant={isOrusContact ? 'branded' : 'neutral'} helpContactDetails={helpContactDetails} />
  },
)
