import { css } from '@emotion/react'
import { X0 } from '@orus.eu/amount'
import { getDiscountRate, type PriceCardSubscriptionUiElement, type QuoteOfferInformation } from '@orus.eu/dimensions'
import { useScreenType } from '@orus.eu/pharaoh'
import { PricingBlock } from '../../subscription/pricing-block'
import { ifStateProxy } from '../if-state-proxy'

export const PriceCardElementBlock = ifStateProxy<PriceCardSubscriptionUiElement>(function PriceCardElementBlock({
  uiElement,
  stateProxy,
  handleSubmit,
  subscriptionId,
  changes,
}) {
  const screenType = useScreenType()
  const handlePaymentRecurrenceChange = stateProxy.useWrite(uiElement.dimensions.paymentRecurrence)

  const quote = stateProxy.read(uiElement.dimensions.hiddenQuote)
  return quote ? (
    <PricingBlock
      offerInformation={
        uiElement.showDetails ? stateProxy.readRequired(uiElement.dimensions.offerInformation) : noOfferInformation
      }
      paymentRecurrence={stateProxy.readRequired(uiElement.dimensions.paymentRecurrence)}
      forbiddenMonthlyPayment={stateProxy.readRequired(uiElement.dimensions.forbiddenMonthlyPayment)}
      commitment={stateProxy.readRequired(uiElement.dimensions.commitment)}
      quote={quote}
      email={stateProxy.read(uiElement.dimensions.email)}
      discount={getDiscountRate(stateProxy.readRequired(uiElement.dimensions.discount)) ?? X0}
      yearlyCommitmentHasDiscount={stateProxy.readRequired(uiElement.dimensions.yearlyCommitmentHasDiscount)}
      onSubscribeClick={uiElement.showSubscribeButton ? handleSubmit : undefined}
      onPaymentRecurrenceChange={handlePaymentRecurrenceChange}
      subscriptionId={subscriptionId}
      css={
        screenType === 'mobile'
          ? css`
              padding: 0;
            `
          : undefined
      }
      changes={changes}
      operatingZone={stateProxy.readRequired(uiElement.dimensions.operatingZone)}
    />
  ) : (
    <></>
  )
})

const noOfferInformation: QuoteOfferInformation = { products: [] }
