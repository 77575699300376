import {
  getCommonClientAttributes,
  getExclusionDescription,
  type Exclusion,
  type RiskCarrierProductType,
  type SubscriptionStepId,
} from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { TemporaryProblemError, useCrash, useLanguage } from '@orus.eu/pharaoh'
import { useNavigate } from '@tanstack/react-router'
import { useCallback } from 'react'
import { sendMessage } from '../../../lib/tracking/tracking'

export type HandleExclusionParams = {
  operatingZone: OperatingZone
  subscriptionId: string
  stepId: SubscriptionStepId | undefined
  exclusion: Exclusion
  riskCarrierProducts?: RiskCarrierProductType[]
  activityName?: string
}

export type HandleExclusionProblem = (params: HandleExclusionParams) => void

export function useHandleExclusionProblem(): HandleExclusionProblem {
  const navigate = useNavigate()
  const crash = useCrash()
  const language = useLanguage()
  return useCallback(
    (params: HandleExclusionParams) => {
      const { subscriptionId, riskCarrierProducts, activityName, stepId, operatingZone } = params
      if (!stepId) return
      const exclusionId = params.exclusion.name
      const exclusionDescription = getExclusionDescription(params.exclusion, language)
      if (exclusionId === 'wakamApiFailureExclusion') {
        crash(new TemporaryProblemError())
        return
      }

      sendMessage({
        event: 'form_exclusion_displayed',
        subscription_id: subscriptionId,
        denial_id: exclusionId,
        denial_message: exclusionDescription,
        policy_type: riskCarrierProducts ? riskCarrierProducts.join(',') : '',
        activity_name: activityName || '',
        ...getCommonClientAttributes(riskCarrierProducts),
      })
      void navigate({
        to: '/subscribe-exclusion/$subscriptionId',
        params: { subscriptionId },
        search: { description: exclusionDescription, stepId, operatingZone },
      })
    },
    [crash, navigate, language],
  )
}
