import { css } from '@emotion/react'
import { amountToNumber } from '@orus.eu/amount'
import {
  esRcphOptionDPPDimension,
  esRcphOptionGlDimension,
  esRcphOptionRezpCruzDimension,
  esRcphOptionWorldDimension,
  hiddenQuoteDimension,
  localizeAmountAndRecurrence,
  mrphOptionAssistanceDimension,
  mrphOptionPeActiveDimension,
  mrphOptionPeLabels,
  mrphOptionPeValues,
  mrphOptionPjDimension,
  mrphOptionRcalDimension,
  mrphOptionRceDimension,
  mrpwOptionBdgDimension,
  mrpwOptionPeDimension,
  mrpwOptionPjDimension,
  mrpwOptionPvvDimension,
  rcdaOptionSwimmingPoolDimension,
  rcphOptionCyberDefaillanceFournisseurDimension,
  rcphOptionCyberDimension,
  rcphOptionCyberDommagesPeDimension,
  rcphOptionCyberDommagesTiersDimension,
  rcphOptionCyberFraudePiratageDimension,
  rcphOptionMondeDimension,
  rcphOptionMrpSmallOfficeDimension,
  rcphOptionRceDimension,
  rcphOptionTdmiDimension,
  type BooleanDimension,
  type PolicyOptionalGuarantee,
  type PolicyOptionalGuaranteesType,
  type ProductInformation,
  type ProductInformationId,
  type QuotePageBodySubscriptionUiElement,
} from '@orus.eu/dimensions'
import { TechnicalError, checkDefinedAndNotNull } from '@orus.eu/error'
import {
  Avatar,
  Button,
  Chip,
  DropdownWithDescription,
  SelectableCard,
  spacing,
  useDialogVisibility,
  useLanguage,
  useOpenChat,
  useTranslate,
} from '@orus.eu/pharaoh'
import { Fragment, memo, useCallback, useMemo } from 'react'
import { assert } from '../../../../../lib/errors'
import { logger } from '../../../../../lib/logger'
import { sendMessage } from '../../../../../lib/tracking/tracking'
import { usePermissions } from '../../../../../lib/use-permissions'
import { OptionalGuaranteeDialog } from '../../../../organisms/guarantee/option-list'
import { optionalGuaranteeImages } from '../../../../organisms/guarantee/optional-guarantee-images'
import type { StateProxy } from '../../subscription-v2-props'
import { historyTakeoverOptionConfiguration } from './history-takeover-option-configuration'
import type { OptionDimensionConfiguration } from './option-dimension-configuration'

export type ProductOptionsListProps = {
  productInformation: ProductInformation
  stateProxy: StateProxy
  subscriptionId: string
  uiElement: QuotePageBodySubscriptionUiElement
}
export const ProductOptionsList = memo<ProductOptionsListProps>(function ProductOptionsList({
  productInformation,
  stateProxy,
  subscriptionId,
  uiElement,
}) {
  const { type } = usePermissions()
  const isFunnel = type === 'client'

  const supportedOptions = useSuportedOptions(productInformation)

  const isOptionPeActive = stateProxy.read(uiElement.dimensions.mrphOptionPeActive)

  const handleChangeDropdown = useCallback(
    (value?: string | null) => {
      if (!value) return
      stateProxy.write(uiElement.dimensions.mrphOptionPe, value)
      sendMessage({
        subscription_id: subscriptionId,
        event: 'subscription_updated_front',
        mrph_option_pe: value,
        yearly_total_premium: amountToNumber(
          stateProxy.readRequired(uiElement.dimensions.hiddenQuote).yearlyTotalPremium,
        ),
        mrph_deductible: null,
        es_rcph_deductible: null,
        rcda_deductible: null,
        option: null,
        rcph_loi: null,
        es_rcph_loi: null,
        rcda_history_takeover_years: null,
      })
    },
    [stateProxy, subscriptionId, uiElement],
  )

  if (supportedOptions.length === 0) return <></>

  if (supportedOptions.length === 1 && supportedOptions[0].type === 'HISTORY_TAKEOVER') return <></>
  const { product } = productInformation

  const isOnlyHiddenOptions = supportedOptions.every(
    (option) =>
      checkDefinedAndNotNull(optionConfigurations[product][option.type]).type === 'hidden' ||
      (option.type === 'cyber' && isFunnel),
  )

  const mrphOptionPeValue = stateProxy.readRequired(uiElement.dimensions.mrphOptionPe)

  const isOptionPeAvantageAvailable = stateProxy.read(uiElement.dimensions.mrphOptionPeAvantageAvailable)

  if (isOnlyHiddenOptions) return null

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${spacing[30]};
      `}
    >
      {supportedOptions.map((option) => {
        const configuration = checkDefinedAndNotNull(optionConfigurations[product][option.type])
        return configuration.type !== 'hidden' && !configuration.customEditor ? (
          <Fragment key={option.type}>
            {option.type === 'cyber' && isFunnel ? null : (
              <>
                <ProductOptionListItem
                  product={product}
                  option={option}
                  stateProxy={stateProxy}
                  configuration={configuration}
                  subscriptionId={subscriptionId}
                />
                {option.type === 'PE' && isOptionPeActive && isOptionPeAvantageAvailable ? (
                  <DropdownWithDescription
                    title=""
                    description={'Formules option perte financières'}
                    value={mrphOptionPeValue}
                    values={mrphOptionPeValues}
                    labels={mrphOptionPeLabels}
                    onChange={handleChangeDropdown}
                    aria-label="Formule perte financière"
                    size="small"
                  />
                ) : null}
              </>
            )}
          </Fragment>
        ) : (
          <></>
        )
      })}
    </div>
  )
})

export type ProductOptionListItemProps = {
  product: ProductInformationId
  option: PolicyOptionalGuarantee
  stateProxy: StateProxy
  configuration: OptionDimensionConfiguration
  subscriptionId: string
  dataTestId?: string
}
export const ProductOptionListItem = memo<ProductOptionListItemProps>(function ProductOptionListItem({
  product,
  option,
  stateProxy,
  configuration,
  subscriptionId,
  dataTestId,
}) {
  const openChat = useOpenChat()
  const language = useLanguage()
  const translate = useTranslate()
  const {
    show: showDetailDialog,
    hide: hideDetailDialog,
    visible: detailDialogVisible,
  } = useDialogVisibility(`option-${option.type}`)

  /**
   * Handle option change: return true if option is actually being changed or false
   * if change is not supported.
   */
  const handleChange = useCallback(
    (selected: boolean): boolean => {
      assert(configuration, 'Unexpected falsy configuration')
      assert(configuration.type !== 'hidden', 'Unexpected hidden type')

      if (configuration.type === 'readonly') {
        alert(
          selected
            ? 'Contactez votre conseiller pour ajouter cette option'
            : 'Contactez votre conseiller pour retirer cette option',
        )
        openChat()
        return false
      }

      const dimension: BooleanDimension<string> = configuration.dimension
      stateProxy.write(dimension, selected)
      sendMessage({
        event: 'subscription_updated_front',
        option: `${dimension.name} - ${selected}`,
        yearly_total_premium: amountToNumber(stateProxy.readRequired(hiddenQuoteDimension).yearlyTotalPremium),
        mrph_deductible: null,
        es_rcph_deductible: null,
        rcda_deductible: null,
        rcda_history_takeover_years: null,
        rcph_loi: null,
        es_rcph_loi: null,
        subscription_id: subscriptionId,
        mrph_option_pe: null,
      })
      return true
    },
    [configuration, openChat, stateProxy, subscriptionId],
  )

  if (!configuration) {
    // This is most likely due to adding an option and not specifying the configuration for the quote
    // page. We don't crash for this, but we log an error to make sure the configuration is updated.
    logger.error(
      new TechnicalError('Missing option configuration, hiding option in quote page', {
        context: {
          product,
          optionType: option.type,
        },
      }),
    )
    return <></>
  }

  if (configuration.type === 'hidden') return <></>

  // the whole card is a label, so that clicks on the card act as clicks on the checkbox
  return (
    <SelectableCard
      title={option.name}
      dataTestId={dataTestId}
      avatar={<Avatar size="40" variant="full" src={optionalGuaranteeImages[option.type]} />}
      isActive={option.isActive}
      onCheckboxChange={handleChange}
      button={
        <Button variant="text" size="small" onClick={showDetailDialog}>
          {translate('subscription_funnel_product_indicator_learn_more')}
        </Button>
      }
      {...(option.price ? { subtitle: localizeAmountAndRecurrence(option.price, language) } : {})}
      {...(option.isRecommanded
        ? {
            chip: (
              <Chip size="small" variant="warning">
                Recommandé
              </Chip>
            ),
          }
        : {})}
      {...(detailDialogVisible
        ? {
            detailDialog: <OptionalGuaranteeDialog optionalGuarantee={option} onClose={hideDetailDialog} />,
          }
        : {})}
    />
  )
})

const optionConfigurations: Record<
  ProductInformationId,
  { [key in PolicyOptionalGuaranteesType]?: OptionDimensionConfiguration }
> = {
  mrph: {
    PE: { type: 'editable', dimension: mrphOptionPeActiveDimension },
    PJ: { type: 'editable', dimension: mrphOptionPjDimension },
    RCAL: { type: 'editable', dimension: mrphOptionRcalDimension },
    RCE: { type: 'editable', dimension: mrphOptionRceDimension },
    ASSISTANCE: { type: 'editable', dimension: mrphOptionAssistanceDimension },
    FREEDOM_ADVANTAGE: { type: 'hidden' },
  },
  mrpw: {
    BDG: { type: 'editable', dimension: mrpwOptionBdgDimension },
    PE: { type: 'editable', dimension: mrpwOptionPeDimension },
    PJ: { type: 'editable', dimension: mrpwOptionPjDimension },
    PVV: { type: 'editable', dimension: mrpwOptionPvvDimension },
    RCT: { type: 'hidden' },
    BOOST: { type: 'hidden' },
  },
  'rcph-pj': {},
  'rcph-cyber': {
    cyberDommagesPe: { type: 'editable', dimension: rcphOptionCyberDommagesPeDimension },
    cyberDefaillanceFournisseur: { type: 'editable', dimension: rcphOptionCyberDefaillanceFournisseurDimension },
    cyberDommagesTiers: { type: 'editable', dimension: rcphOptionCyberDommagesTiersDimension },
    cyberFraudePiratage: { type: 'editable', dimension: rcphOptionCyberFraudePiratageDimension },
  },
  'rcph-tdmi': {},
  restaurant: {
    optionShatteredGlass: { type: 'readonly' },
    optionMarketValueLoss: { type: 'readonly' },
    optionOperationalLoss: { type: 'readonly' },
    optionLegalProtection: { type: 'readonly' },
  },
  'rc-pro': {
    rce: { type: 'editable', dimension: rcphOptionRceDimension },
    world: { type: 'editable', dimension: rcphOptionMondeDimension },
    mrpSmallOffice: { type: 'editable', dimension: rcphOptionMrpSmallOfficeDimension },
    tdmi: { type: 'editable', dimension: rcphOptionTdmiDimension },
    cyber: { type: 'editable', dimension: rcphOptionCyberDimension },
  },
  muta: {},
  rcda: {
    HISTORY_TAKEOVER: historyTakeoverOptionConfiguration,
    SWIMMING_POOL: { type: 'editable', dimension: rcdaOptionSwimmingPoolDimension },
  },
  'rcda-rc-pro': {},
  'es-rcph-pro': {
    esRcphCrossLiability: { type: 'editable', dimension: esRcphOptionRezpCruzDimension },
    esRcphWorldwideCoverage: { type: 'editable', dimension: esRcphOptionWorldDimension },
    esRcphOptionGl: { type: 'editable', dimension: esRcphOptionGlDimension },
  },
  'es-rcph-general': {
    esRcphPureFinancialLossLiability: { type: 'editable', dimension: esRcphOptionDPPDimension },
    esRcphCrossLiability: { type: 'editable', dimension: esRcphOptionRezpCruzDimension },
  },
}

function useSuportedOptions(productInformation: ProductInformation): PolicyOptionalGuarantee[] {
  const dimensionsPerOptionType = optionConfigurations[productInformation.product]
  const options = productInformation.optionalGuarantees
  return useMemo(
    () => options.filter((option) => option.type in dimensionsPerOptionType),
    [dimensionsPerOptionType, options],
  )
}
