import { css } from '@emotion/react'
import type { BooleanSubscriptionUiElement } from '@orus.eu/dimensions'
import {
  CheckboxContainer,
  RowButtonV2,
  RowContainerV2,
  Text,
  TextInputLabelWrapper,
  spacing,
  useLanguage,
  useTranslate,
} from '@orus.eu/pharaoh'
import { memo, useCallback, useEffect } from 'react'
import { ifStateProxy } from '../if-state-proxy'
import type { StateProxy, SubscriptionElementBlockProps } from '../subscription-v2-props'
import { useUiElementTexts } from './use-ui-element-texts'

export const BooleanSubscriptionUiElementBlock = ifStateProxy<BooleanSubscriptionUiElement>(
  function BooleanSubscriptionUiElementBlock(props) {
    const language = useLanguage()
    const { uiElement, stateProxy, context } = props
    const value = stateProxy.read(uiElement.dimension)
    const uiElementTexts = useUiElementTexts(uiElement)

    const handleChange = useCallback(
      (value?: boolean | null | undefined) => {
        if (value == undefined) return
        stateProxy.write(uiElement.dimension, value)
      },
      [stateProxy, uiElement],
    )

    useEffect(() => {
      if (
        value == undefined &&
        (context === 'backoffice' ||
          ('initializeValueWhenDisplayed' in uiElement && uiElement.initializeValueWhenDisplayed))
      ) {
        handleChange(false)
      }
    }, [handleChange, uiElement, value, context])
    switch (uiElement.variant) {
      case 'checkbox-card':
        return (
          <CheckboxContainer
            checked={!!value}
            onChange={handleChange}
            border={true}
            infoTooltip={uiElementTexts?.hint ?? uiElement.dimension.hints?.[language]}
          >
            {uiElementTexts?.label || uiElement.dimension.displayNames[language]}
          </CheckboxContainer>
        )
      case 'checkbox':
        return (
          <CheckboxContainer checked={!!value} onChange={handleChange}>
            {uiElementTexts?.label || uiElement.dimension.displayNames[language]}
          </CheckboxContainer>
        )
      case 'yes-no-buttons':
        return context === 'selfonboarding' ? (
          uiElement.hideLabel ? (
            <YesNoButtons {...props} handleChange={handleChange} />
          ) : (
            <TextInputLabelWrapper
              label={uiElementTexts?.label ?? uiElement.dimension.displayNames[language]}
              infoTooltip={uiElementTexts?.hint ?? uiElement.dimension.hints?.[language]}
            >
              <YesNoButtons {...props} handleChange={handleChange} />
            </TextInputLabelWrapper>
          )
        ) : (
          <CheckboxContainer checked={!!value} onChange={handleChange}>
            {uiElementTexts?.label || uiElement.dimension.displayNames[language]}
          </CheckboxContainer>
        )
      case 'checkbox-with-title':
        return (
          <CheckboxWithTitle
            uiElement={uiElement}
            stateProxy={stateProxy}
            checked={!!value}
            handleChange={handleChange}
          />
        )
    }
  },
)

const CheckboxWithTitle = memo<{
  uiElement: BooleanSubscriptionUiElement & { variant: 'checkbox-with-title' }
  stateProxy: StateProxy
  checked: boolean
  handleChange: (value?: boolean | null | undefined) => void
}>(function CheckboxWithTitle({ uiElement, checked, handleChange }) {
  const language = useLanguage()
  const uiElementTexts = useUiElementTexts(uiElement)
  const label = uiElementTexts?.label ?? uiElement.dimension.displayNames[language]
  const hint = uiElementTexts?.hint ?? uiElement.dimension.hints?.[language]
  return (
    <div>
      <Text variant="subtitle2">{label}</Text>
      <CheckboxContainer
        css={css`
          margin-top: ${spacing[50]};
        `}
        checked={checked}
        onChange={handleChange}
      >
        {hint ?? label}
      </CheckboxContainer>
    </div>
  )
})

const YesNoButtons = memo<
  SubscriptionElementBlockProps<BooleanSubscriptionUiElement> & {
    stateProxy: StateProxy
    handleChange: (value?: boolean | null | undefined) => void
  }
>(function YesNoButtons({ uiElement, handleSubmit, handleChange }) {
  const translate = useTranslate()
  const handleYesClick = useCallback(() => {
    if ('disableSubmit' in uiElement && uiElement.disableSubmit) {
      handleChange(true)
    } else {
      handleSubmit(undefined, { [uiElement.dimension.name]: true })
    }
  }, [handleChange, handleSubmit, uiElement])

  const handleNoClick = useCallback(() => {
    handleSubmit(undefined, { [uiElement.dimension.name]: false })
  }, [handleSubmit, uiElement])

  return (
    <RowContainerV2>
      <RowButtonV2 onClick={handleYesClick} primaryText={translate('yes')} />
      <RowButtonV2 onClick={handleNoClick} primaryText={translate('no')} />
    </RowContainerV2>
  )
})
