import type {
  ActivityGroupPolicyMappingEntry,
  DisplayedGroupsUi,
  GroupData,
} from '@orus.eu/backend/src/views/activity-grouping-view'
import type { OperatingZone } from '@orus.eu/operating-zone'
import type { Product } from '@orus.eu/product'
import { trpc } from '../client'

/**
 * Interface used but the activity autocompletes load the list data
 */
export type CompleteGroupLoader = {
  getGroups: () => Promise<GroupData[]>
}

export type SelfOnboardingGroupLoader = {
  getGroups: () => Promise<DisplayedGroupsUi[]>
}

/**
 * Interface used by the activity selection dialogs to load the list data, and display more details about
 * the selected item.
 */
export type GroupMapping = {
  getGroupMapping: (input: GroupMappingInput | undefined) => Promise<ActivityGroupPolicyMappingEntry | undefined>
}

export type GroupMappingInput = {
  group: string
  operatingZone: OperatingZone
}

export type SelfOnboardingGroupMappingLoader = GroupMapping & SelfOnboardingGroupLoader

export const backofficeGroupMappingLoader: CompleteGroupLoader = {
  async getGroups(): Promise<GroupData[]> {
    return trpc.activities.getAllGroupsForBackoffice.query()
  },
}

export const selfOnboardingGroupMappingLoader: SelfOnboardingGroupMappingLoader = {
  async getGroups(): Promise<DisplayedGroupsUi[]> {
    // group products is not explicitely displayed during self-onboarding
    const products: Product[] = []
    return (await trpc.selfOnboarding.getGroupsWithSelfOnboarding.query()).map((group) => ({
      name: group.name,
      description: group.description,
      products,
      mainActivityId: group.mainActivityId,
      groupName: group.groupName,
      operatingZone: group.operatingZone,
    }))
  },
  async getGroupMapping(input): Promise<ActivityGroupPolicyMappingEntry | undefined> {
    return input ? trpc.activities.getGroupMappingWithSelfOnboarding.query(input) : undefined
  },
}
