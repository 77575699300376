import type { PersonalDataModalOverQuoteUiElement } from '@orus.eu/dimensions'
import { Dialog, FlexColumn, Text, spacing, useTranslate } from '@orus.eu/pharaoh'

import { ifStateProxy } from '../if-state-proxy'
import { SubscriptionValidationIssueBlock } from '../subscription-v2-validation-issue'
import { DataPolicyAcceptanceSubscriptionUiElementBlock } from './DataPolicyAcceptanceSubscriptionUiElementBlock'
import { StringSubscriptionUiElementBlock } from './string-element'

export const PersonalDataOverQuoteSubscriptionUiElementBlock = ifStateProxy<PersonalDataModalOverQuoteUiElement>(
  function PersonalDataOverQuoteSubscriptionUiElementBlock({ uiElement, ...props }) {
    const translate = useTranslate()
    return (
      <Dialog
        formId="all-subscriptions-personal-data"
        blurOverlay
        size="medium"
        title={translate('subscription_funnel_quote_available')}
        primaryActionLabel={translate('subscription_funnel_quote_see_my_quote')}
        secondaryActionLabel={translate('back')}
        onPrimaryAction={props.handleSubmit}
        primaryActionDisabled={!props.nextEnabled}
        onSecondaryAction={props.goBackToPreviousStep}
      >
        <FlexColumn gap={spacing[50]}>
          {props.selectedProductName ? <Text variant="body2">Assurance : {props.selectedProductName}</Text> : null}
          <Text variant="body2">{translate('subscription_funnel_personal_data_over_quote_description')}</Text>
          <SubscriptionValidationIssueBlock issue={props.serverValidationIssue} />
          <StringSubscriptionUiElementBlock
            {...props}
            uiElement={{
              type: 'email',
              dimension: uiElement.dimensions.email,
            }}
          />
          <StringSubscriptionUiElementBlock
            {...props}
            uiElement={{
              type: 'phone',
              dimension: uiElement.dimensions.phone,
            }}
          />
          <DataPolicyAcceptanceSubscriptionUiElementBlock
            {...props}
            uiElement={{
              type: 'data-policy-acceptance',
              dimensions: {
                value: uiElement.dimensions.hasAccepted,
                hasAcceptedCommercialMessages: uiElement.dimensions.hasAcceptedCommercialMessages,
                operatingZone: uiElement.dimensions.operatingZone,
              },
            }}
          />
        </FlexColumn>
      </Dialog>
    )
  },
)
