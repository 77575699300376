import { css } from '@emotion/react'
import {
  productInformationIdToProduct,
  type ActivitiesSelectorSubscriptionUiElement,
  type PossibleOffer,
  type ProductInformationId,
} from '@orus.eu/dimensions'

import { spacing, Text, useDialogVisibility, useUiContext } from '@orus.eu/pharaoh'
import { ActivitiesSelector, ActivityCoverageDialog } from '@orus.eu/pharaoh/src/components/features/rcda'
import type { Product } from '@orus.eu/product'
import { useCallback, useMemo, useState } from 'react'
import { trpcReact } from '../../../../../client'
import { assert } from '../../../../../lib/errors'
import { ifStateProxy } from '../../if-state-proxy'
import { ActivityDialog } from './activity-dialog'
import { RemoveActivityDialog } from './remove-activity-dialog'

type Activity = { id: string; name: string; description: string }

export const ActivitiesSelectorSubscriptionUiElementBlock = ifStateProxy<ActivitiesSelectorSubscriptionUiElement>(
  function ActivitiesSelectorSubscriptionUiElementBlock(props) {
    const { uiElement, stateProxy, context } = props

    const screenVariant = useUiContext()
    const possibleOffers = stateProxy.readRequired(uiElement.dimensions.possibleOffers)
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const rcphProduct = stateProxy.read(uiElement.dimensions.rcphProduct)

    let product: Product | undefined = undefined
    const possibleOffersEntries = Object.entries(possibleOffers) as [ProductInformationId, PossibleOffer][]
    possibleOffersEntries.forEach(([productInformationId, offer]) => {
      if (offer.isRecommended) {
        product = productInformationIdToProduct[productInformationId]
      }
    })

    assert(product, 'Recommended product should always be available for activities selector block')

    const activityRaw = stateProxy.readRequired(uiElement.dimensions.activity)
    const complementaryActivitiesRaw = stateProxy.read(uiElement.dimensions.complementaryActivities)
    const maxActivitiesCount = stateProxy.read(uiElement.dimensions.maxActivitiesCount)
    const writeComplementaryActivities = stateProxy.useWrite(uiElement.dimensions.complementaryActivities)

    const mainActivity = useMemo(() => ({ id: activityRaw.activity, name: activityRaw.displayName }), [activityRaw])

    const complementaryActivities = useMemo(
      () =>
        (complementaryActivitiesRaw ?? []).map((activity) => ({
          id: activity.activity,
          name: activity.displayName,
        })),
      [complementaryActivitiesRaw],
    )

    const allSelectedActivityIds = useMemo(() => {
      return new Set([mainActivity.id, ...complementaryActivities.map((activity) => activity.id)])
    }, [mainActivity, complementaryActivities])

    const [activityToRemove, setActivityToRemove] = useState<{ id: string; name: string } | undefined>(undefined)

    const [targetedActivity, setTargetedActivity] = useState<{ id: string; name: string } | undefined>(undefined)

    const {
      show: showAddActivityDialog,
      hide: hideAddActivityDialog,
      visible: visibleAddActivityDialog,
    } = useDialogVisibility('add-activity')

    const {
      show: showRemoveActivityDialog,
      hide: hideRemoveActivityDialog,
      visible: visibleRemoveActivityDialog,
    } = useDialogVisibility('remove-activity')

    const {
      show: showActivityDetailsDialog,
      hide: hideActivityDetailsDialog,
      visible: visibleActivityDetailsDialog,
    } = useDialogVisibility('activity-details')

    const handleAddActivity = useCallback(
      (activity: Activity) => {
        hideAddActivityDialog()
        writeComplementaryActivities([
          ...(complementaryActivitiesRaw ?? []),
          { activity: activity.id, displayName: activity.name },
        ])
      },
      [complementaryActivitiesRaw, writeComplementaryActivities, hideAddActivityDialog],
    )

    const handleRemoveActivity = useCallback(
      (removedActivity: { id: string; name: string }) => {
        setActivityToRemove(removedActivity)
        showRemoveActivityDialog()
      },
      [showRemoveActivityDialog],
    )

    const handleRemoveActivityConfirmed = useCallback(
      (removedActivity: { id: string; name: string }) => {
        writeComplementaryActivities(
          (complementaryActivitiesRaw ?? []).filter((activity) => activity.activity !== removedActivity.id),
        )
      },
      [complementaryActivitiesRaw, writeComplementaryActivities],
    )

    const [productActivitiesScope] = trpcReact.productScope.getProductScopeExclusions.useSuspenseQuery()
    const { scope } = productActivitiesScope

    const isActivityCoverage = scope && scope[mainActivity.id]

    // We don't have particular details to show for activities except for RCDA
    const showActivityDetails = useCallback(
      (activity: { id: string; name: string }) => {
        setTargetedActivity(activity)
        showActivityDetailsDialog()
      },
      [showActivityDetailsDialog],
    )
    const effectiveShowActivityDetails =
      ['rcda', 'rcph'].includes(product) && isActivityCoverage ? showActivityDetails : undefined

    return (
      <>
        {screenVariant !== 'backoffice' ? (
          <Text
            variant="body2Medium"
            css={css`
              margin-bottom: ${spacing[50]};
            `}
          >
            Vos activités ({maxActivitiesCount} maximum)
          </Text>
        ) : (
          <></>
        )}
        {visibleAddActivityDialog && (
          <ActivityDialog
            operatingZone={operatingZone}
            onClose={hideAddActivityDialog}
            onAddActivity={handleAddActivity}
            product={product}
            rcphProduct={rcphProduct}
            allSelectedActivityIds={allSelectedActivityIds}
          />
        )}
        {visibleRemoveActivityDialog && activityToRemove && (
          <RemoveActivityDialog
            activity={activityToRemove}
            onClose={hideRemoveActivityDialog}
            onRemoveActivity={handleRemoveActivityConfirmed}
          />
        )}
        <ActivitiesSelector
          showMainActivity={context === 'selfonboarding'}
          mainActivity={mainActivity}
          complementaryActivities={complementaryActivities}
          maxActivitiesCount={maxActivitiesCount}
          onAddActivity={showAddActivityDialog}
          onMoreDetailsClick={effectiveShowActivityDetails}
          onRemoveActivity={handleRemoveActivity}
          isJustifyExperienceTextVisible={product === 'rcda'}
        />
        {visibleActivityDetailsDialog && targetedActivity && scope && isActivityCoverage && (
          <ActivityCoverageDialog
            activity={targetedActivity.name}
            mainActivities={scope[targetedActivity.id].mainActivities}
            secondaryActivities={scope[targetedActivity.id].secondaryActivities}
            forbiddenActivities={scope[targetedActivity.id].forbiddenActivities}
            onClose={hideActivityDetailsDialog}
          />
        )}
      </>
    )
  },
)
