import { productSelectedDimension, type ProductSelectionBackofficeUiElement } from '@orus.eu/dimensions'
import { ProductBadge } from '@orus.eu/pharaoh/src/components/features/backoffice-quote-editor/badges'
import { Row, RowContainer } from '@orus.eu/pharaoh/src/components/rows'
import type { Product } from '@orus.eu/product'
import { useCallback, useEffect, useState } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const ProductSelectionBackofficeUiElementBlock = ifStateProxy<ProductSelectionBackofficeUiElement>(
  function ProductSelectionBackofficeUiElementBlock(props) {
    const { uiElement, stateProxy } = props

    const availableRiskCarrierProducts = stateProxy.read(uiElement.dimensions.availableRiskCarrierProducts)

    const [loadingState, setLoadingState] = useState<boolean[]>()

    const cleanLoadingState = useCallback(
      (products: Product[]) => {
        setLoadingState(products.map(() => false))
      },
      [setLoadingState],
    )

    useEffect(() => {
      if (availableRiskCarrierProducts) cleanLoadingState(availableRiskCarrierProducts)
    }, [availableRiskCarrierProducts, cleanLoadingState])

    const handleProductClick = useCallback(
      (product: Product) => {
        setLoadingState((previous) => {
          const newState = [...(previous ?? [])]
          newState[availableRiskCarrierProducts?.indexOf(product) ?? 0] = true
          return newState
        })
        stateProxy.write(productSelectedDimension[product], !stateProxy.read(productSelectedDimension[product]))
      },
      [stateProxy, availableRiskCarrierProducts],
    )

    return (
      <>
        <RowContainer variant="spacing" checkbox size="small" horizontal>
          {availableRiskCarrierProducts && availableRiskCarrierProducts.length > 0 ? (
            availableRiskCarrierProducts.map((product, index) => (
              <Row
                badge={<ProductBadge product={product} />}
                selected={stateProxy.read(productSelectedDimension[product])}
                onClick={() => handleProductClick(product)}
                key={product}
                isLoading={loadingState?.[index]}
                ariaLabel={`Sélection produit ${product}`}
              />
            ))
          ) : (
            <></>
          )}
        </RowContainer>
      </>
    )
  },
)
