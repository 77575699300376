import type { Exclusion, StatementRefusalUiElement } from '@orus.eu/dimensions'
import { Button, useTranslate } from '@orus.eu/pharaoh'
import { useCallback } from 'react'
import { ifStateProxy } from '../if-state-proxy'
import { useHandleExclusionProblem } from '../use-handle-exclusion-problem'

export const StatementRefusalUiElementBlock = ifStateProxy<StatementRefusalUiElement>(
  function StatementRefusalUiElementBlock({ stateProxy, uiElement, subscriptionId, stepId }) {
    const handleExclusionProblem = useHandleExclusionProblem()
    const operatingZone = stateProxy.readRequired(uiElement.dimensions.operatingZone)
    const riskCarrierProducts = stateProxy.read(uiElement.dimensions.riskCarrierProducts)
    const activity = stateProxy.read(uiElement.dimensions.activity)
    const activityName = activity?.displayName
    const handleRefuse = useCallback(() => {
      const exclusion: Exclusion = { name: 'statementRefusedExclusion', message: 'exclusion_statement_refused' }
      handleExclusionProblem({ operatingZone, subscriptionId, stepId, exclusion, riskCarrierProducts, activityName })
    }, [activityName, handleExclusionProblem, operatingZone, riskCarrierProducts, stepId, subscriptionId])

    const translate = useTranslate()
    return (
      <Button variant="secondary" onClick={handleRefuse}>
        {translate('subscription_funnel_statement_refuse')}
      </Button>
    )
  },
)
