import type { OperatingZone } from '@orus.eu/operating-zone'
import type { Product } from '@orus.eu/product'
import type { BooleanDimension } from './dimension/boolean-dimension.js'
import { esRcphProductDimension, esRcphSelectedDimension } from './dimension/es-rcph/index.js'
import { mrphSelectedDimension } from './dimension/mrph/index.js'
import { mrpwSelectedDimension } from './dimension/mrpw/index.js'
import { mutaSelectedDimension } from './dimension/muta/index.js'
import type { ProductInformationId } from './dimension/quote-offer-information-dimension.js'
import { rcdaSelectedDimension } from './dimension/rcda/index.js'
import { rcphOptionCyberDimension, rcphOptionTdmiDimension, rcphSelectedDimension } from './dimension/rcph/index.js'

export const productSelectionDimensions = [
  mrphSelectedDimension,
  mrpwSelectedDimension,
  rcphSelectedDimension,
  mutaSelectedDimension,
  rcdaSelectedDimension,
  esRcphSelectedDimension,
] as const
export const productSelectionDimensionsByProduct = {
  mrph: mrphSelectedDimension,
  mrpw: mrpwSelectedDimension,
  rcph: rcphSelectedDimension,
  muta: mutaSelectedDimension,
  rcda: rcdaSelectedDimension,
  'es-rcph': esRcphSelectedDimension,
} as const satisfies Record<Product, BooleanDimension<string>>

export const displayNameByProduct: Record<Product, string> = {
  mrph: 'Multirisque local',
  mrpw: 'Multirisque',
  rcph: 'Responsabilité Civile Professionnelle',
  muta: 'Mutuelle',
  rcda: 'Responsabilité Civile Décennale',
  'es-rcph': 'Responsabilidad Civil Profesional',
}

export const activableProductInformationIdSelectionDimensions = [
  mrphSelectedDimension,
  mrpwSelectedDimension,
  rcphSelectedDimension,
  mutaSelectedDimension,
  rcdaSelectedDimension,
  rcphOptionCyberDimension,
  rcphOptionTdmiDimension,
  esRcphSelectedDimension,
  esRcphProductDimension,
] as const

export const productInformationIdToProduct: Record<ProductInformationId, Product> = {
  mrph: 'mrph',
  mrpw: 'mrpw',
  muta: 'muta',
  'rc-pro': 'rcph',
  'rcda-rc-pro': 'rcda',
  rcda: 'rcda',
  'rcph-cyber': 'rcph',
  'rcph-tdmi': 'rcph',
  'rcph-pj': 'rcph',
  restaurant: 'mrpw',
  'es-rcph-pro': 'es-rcph',
  'es-rcph-general': 'es-rcph',
}

/**
 * Defines which product is available in which operating zone.
 */
export const productOperatingZone: Record<Product, OperatingZone> = {
  mrph: 'fr',
  mrpw: 'fr',
  rcph: 'fr',
  muta: 'fr',
  rcda: 'fr',
  'es-rcph': 'es',
}
