import {
  getInstallmentFeeRateByCode,
  installmentFeeLabels,
  installmentFeeRateValues,
  type InstallmentFeeUiElement,
} from '@orus.eu/dimensions'
import { TechnicalError } from '@orus.eu/error'
import { DropdownFormField, useLanguage } from '@orus.eu/pharaoh'
import { useCallback } from 'react'
import { ifStateProxy } from '../if-state-proxy'

export const InstallmentFeeUiElementBlock = ifStateProxy<InstallmentFeeUiElement>(
  function InstallmentFeeUiElementBlock({ uiElement, stateProxy }) {
    const language = useLanguage()
    const intallmentFee = stateProxy.read(uiElement.dimensions.installmentFee)

    const valueString = intallmentFee && intallmentFee !== 'none' ? intallmentFee.code : null

    const handleChange = useCallback(
      (stringValue?: string | null) => {
        const newValue = stringValue ? getInstallmentFeeRateByCode(stringValue) : 'none'

        if (!newValue) {
          throw new TechnicalError('Unexpected value received for installmentFeeRate from Dropdown component', {
            context: { stringValue },
          })
        }

        stateProxy.write(uiElement.dimensions.installmentFee, newValue)
      },
      [stateProxy, uiElement.dimensions.installmentFee],
    )

    return (
      <DropdownFormField
        size="small"
        label={uiElement.dimensions.installmentFee.displayNames[language]}
        value={valueString}
        placeholder={
          uiElement.dimensions.installmentFee.placeholders
            ? uiElement.dimensions.installmentFee.placeholders[language]
            : undefined
        }
        onChange={handleChange}
        values={installmentFeeRateValues}
        labels={installmentFeeLabels}
        nullLabel={'Aucun frais'}
      />
    )
  },
)
