import styled from '@emotion/styled'
import {
  isSubscriptionValidationStatus,
  subscriptionDocumentDimensionByType,
  subscriptionDocumentTypes,
  type SubscriptionDocument,
  type SubscriptionDocumentType,
  type SubscriptionDocumentTypeNoteKey,
  type SubscriptionDocumentValidationStatus,
} from '@orus.eu/dimensions'
import { ensureError } from '@orus.eu/error'
import { Button, Text, spacing, useCrash, useDialogVisibility, useLanguage } from '@orus.eu/pharaoh'
import {
  DocumentViewerDialog,
  DocumentViewerMenu,
  SubscriptionDocumentNoteDialog,
  type SubscriptionDocumentNoteRecord,
  type SubscriptionDocumentNoteValue,
} from '@orus.eu/pharaoh/src/components/features/document-collection'
import { memo, useCallback, useState, type ComponentProps } from 'react'
import { trpc } from '../../../../../client'
import { getSubscriptionDocumentLink } from '../../../../../lib/download-links'
import type { StateProxy } from '../../subscription-v2-props'

type DocumentOverviewProps = {
  versionedSubscriptionId?: string
  subscriptionId: string
  stateProxy: StateProxy
  neededDocuments: SubscriptionDocumentType[]
  globalOverviewNote: string
  handleGlobalOverviewNoteChange: (value: string) => void
}
type FileDocument = ComponentProps<typeof DocumentViewerDialog>['documents'][number]

type FileDocumentViewed = FileDocument & {
  fileDescription?: string | null
  creationTimestamp: number
  documentType: SubscriptionDocumentType
  documentStatus: SubscriptionDocumentValidationStatus
  documentNotes: SubscriptionDocumentNoteValue
  fileIndex: number
  filesLength: number
}

export const DocumentOverview = memo<DocumentOverviewProps>(function DocumentOverview({
  versionedSubscriptionId,
  subscriptionId,
  stateProxy,
  neededDocuments,
  globalOverviewNote,
  handleGlobalOverviewNoteChange,
}) {
  const language = useLanguage()
  const { hide: hideViewerDialog, show: showViewerDialog, visible: isViewerVisible } = useDialogVisibility('viewer')
  const crash = useCrash()

  const {
    hide: hideDocumentsNotesDialog,
    show: showDocumentsNotesDialog,
    visible: isDocumentsNotesVisible,
  } = useDialogVisibility('notes')

  const subscriptionFiles: FileDocumentViewed[] = neededDocuments.flatMap((documentType) => {
    const dimension = stateProxy.readRequired(subscriptionDocumentDimensionByType[documentType])
    return dimension.files.map((file, index) => {
      return {
        uri: getSubscriptionDocumentLink(subscriptionId, file.id),
        creationTimestamp: file.creationTimestamp,
        fileType: file.mimeType,
        fileIndex: index + 1,
        filesLength: dimension.files.length,
        documentType: documentType,
        documentStatus: dimension.status,
        fileDescription: file.description,
        documentNotes: { value: dimension.notes?.notes, valueTimestamp: dimension.notes?.noteTimestamp },
        fileName: file.name,
      }
    })
  })

  const [currentViewedFileIndex, setCurrentlyViewedFileIndex] = useState<number>(0)

  const subscriptionDocumentNote: SubscriptionDocumentNoteRecord = neededDocuments.reduce((acc, documentType) => {
    const notes = stateProxy.readRequired(subscriptionDocumentDimensionByType[documentType]).notes
    acc[documentType] = { value: notes?.notes, valueTimestamp: notes?.noteTimestamp }
    return acc
  }, {} as SubscriptionDocumentNoteRecord)

  const handleDocumentNoteChange = useCallback(
    (value: SubscriptionDocumentNoteValue, documentType: SubscriptionDocumentType) => {
      const dimension = subscriptionDocumentDimensionByType[documentType]
      const dimensionValue = stateProxy.readRequired(dimension)
      stateProxy.write(subscriptionDocumentDimensionByType[documentType], {
        ...dimensionValue,
        notes: {
          notes: value.value ?? '',
          noteTimestamp: value.valueTimestamp ?? Date.now(),
        },
      })
    },
    [stateProxy],
  )

  const handleOnReviewFinished = useCallback(() => {
    trpc.tracking.sendDocumentReviewMessage
      .mutate({
        event: 'subscription_review_finished',
        versioned_subscription_id: versionedSubscriptionId ?? subscriptionId + '/0',
        global_document_note: globalOverviewNote,
        ...subscriptionDocumentTypes.reduce(
          (acc, documentType) => {
            const dimension = subscriptionDocumentDimensionByType[documentType]
            const dimensionValue = stateProxy.read(dimension)
            acc[documentType] = dimensionValue?.status
              ? dimensionValue.status !== 'none'
                ? dimensionValue.status
                : null
              : null
            return acc
          },
          {} as Record<SubscriptionDocumentType, SubscriptionDocumentValidationStatus | null>,
        ),
        ...subscriptionDocumentTypes.reduce(
          (acc, documentType) => {
            const dimension = subscriptionDocumentDimensionByType[documentType]
            const dimensionValue = stateProxy.read(dimension)
            acc[`note_${documentType}`] = dimensionValue?.notes?.notes ?? null
            return acc
          },
          {} as Record<SubscriptionDocumentTypeNoteKey, string | null>,
        ),
      })
      .catch((err) => {
        crash(ensureError(err))
      })
    hideViewerDialog()
  }, [crash, globalOverviewNote, hideViewerDialog, stateProxy, subscriptionId, versionedSubscriptionId])

  return (
    <>
      <DocCollectionAdministrationContainer>
        <DocCollectionAdministrationTitleContainer>
          <Text variant="body2Medium">Analyse souscripteur</Text>
          <Text variant="body2">Vérification des documents requis pour le lancement de séquence.</Text>
        </DocCollectionAdministrationTitleContainer>
        <DocCollectionAdministrationButtonContainer>
          <Button variant="secondary" size="small" onClick={showDocumentsNotesDialog}>
            Gérer les notes
          </Button>
          {subscriptionFiles.length > 0 && (
            <Button variant="secondary" size="small" onClick={showViewerDialog}>
              Ouvrir le viewer
            </Button>
          )}
        </DocCollectionAdministrationButtonContainer>
      </DocCollectionAdministrationContainer>
      {isViewerVisible && (
        <DocumentViewerDialog
          title={
            subscriptionDocumentDimensionByType[subscriptionFiles[currentViewedFileIndex].documentType].displayNames[
              language
            ]
          }
          activeDocument={subscriptionFiles[currentViewedFileIndex]}
          setActiveDocument={(document: FileDocument) => {
            const fileIndex = subscriptionFiles.findIndex((file) => document.uri === file.uri) ?? 0
            setCurrentlyViewedFileIndex(fileIndex)
          }}
          documents={subscriptionFiles}
          onClose={hideViewerDialog}
          onReviewFinished={handleOnReviewFinished}
        >
          <DocumentViewerMenu
            documentType={subscriptionFiles[currentViewedFileIndex].documentType}
            filesLength={subscriptionFiles[currentViewedFileIndex].filesLength}
            fileIndex={subscriptionFiles[currentViewedFileIndex].fileIndex}
            name={subscriptionFiles[currentViewedFileIndex].fileName ?? ''}
            description={subscriptionFiles[currentViewedFileIndex].fileDescription ?? ''}
            documentStatus={subscriptionFiles[currentViewedFileIndex].documentStatus}
            onStatusChange={(newStatus) => {
              if (newStatus !== null && isSubscriptionValidationStatus(newStatus)) {
                writeDimension(stateProxy, subscriptionFiles[currentViewedFileIndex].documentType, {
                  status: newStatus,
                })
              }
            }}
            onFileDescriptionChange={(description) => {
              const fileUpdate = {
                files: [
                  ...stateProxy.readRequired(
                    subscriptionDocumentDimensionByType[subscriptionFiles[currentViewedFileIndex].documentType],
                  ).files,
                ].map((file, index) => {
                  if (index === subscriptionFiles[currentViewedFileIndex].fileIndex - 1) {
                    return {
                      ...file,
                      description,
                    }
                  }
                  return file
                }),
              }
              writeDimension(stateProxy, subscriptionFiles[currentViewedFileIndex].documentType, fileUpdate)
            }}
            onNoteChange={(note) => {
              if (!note.valueTimestamp) return
              const noteUpdate = {
                notes: {
                  notes: note.value ?? '',
                  noteTimestamp: note.valueTimestamp,
                },
              }
              writeDimension(stateProxy, subscriptionFiles[currentViewedFileIndex].documentType, noteUpdate)
            }}
            documentNotes={subscriptionFiles[currentViewedFileIndex].documentNotes}
            fileTimestamp={subscriptionFiles[currentViewedFileIndex].creationTimestamp}
          />
        </DocumentViewerDialog>
      )}
      {isDocumentsNotesVisible && (
        <SubscriptionDocumentNoteDialog
          globalOverviewNoteValue={globalOverviewNote}
          onClose={hideDocumentsNotesDialog}
          subscriptionDocumentNoteValueRecord={subscriptionDocumentNote}
          onGlobalOverviewNoteChange={handleGlobalOverviewNoteChange}
          onDocumentNoteChange={handleDocumentNoteChange}
        />
      )}
    </>
  )
})

function writeDimension(
  stateProxy: StateProxy,
  documentType: SubscriptionDocumentType,
  value: Partial<SubscriptionDocument>,
): void {
  const dimension = subscriptionDocumentDimensionByType[documentType]
  const dimensionValue = stateProxy.readRequired(dimension)

  stateProxy.write(subscriptionDocumentDimensionByType[documentType], {
    ...dimensionValue,
    ...value,
  })
}

const DocCollectionAdministrationContainer = styled.div`
  display: flex;
  padding: ${spacing['40']} 0;
  align-items: center;
  align-content: center;
  gap: 16px ${spacing['50']};
  align-self: stretch;
  flex-wrap: wrap;
`

const DocCollectionAdministrationTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex: 1 0 0;
`

const DocCollectionAdministrationButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${spacing['30']};
`
