import type { SubscriptionStepValidationIssue } from '@orus.eu/backend/src/services/subscription/subscription-service'
import type { Contract } from '@orus.eu/backend/src/views/subscriptions/contract-model'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager'
import {
  getElementDimensions,
  type AbstractDimension,
  type LooselyTypedData,
  type SubscriptionBreadcrumb,
  type SubscriptionDetail,
  type SubscriptionStepId,
  type SubscriptionUiElement,
} from '@orus.eu/dimensions'
import { TechnicalError, checkDefinedAndNotNull } from '@orus.eu/error'
import type { OrganizationFunnelInfo } from '@orus.eu/right-access-management'
import { useCallback, useMemo, type FormEvent } from 'react'
import { trpcReact } from '../../../client'
import type { NextEnabledContributor } from './modular-next-enabled'

/**
 * Props that are common across all subscription steps and subscription steps UI elements
 */
export type CommonSubscriptionProps = {
  subscriptionId: string
  /**
   * Always defined in the subscription funnel, undefined in the backoffice
   */
  stepId: SubscriptionStepId | undefined
  /**
   * The id of the customer who owns the subscription, or undefined if the subscription doesn't
   * belong to a signed customer
   */
  customerId: string | undefined
  contract?: Contract
  versionedSubscriptionId: string | undefined
  handleSubmit(event?: FormEvent, changes?: LooselyTypedData): void
  /**
   * @deprecated use handleSubmit(event). This method exists only for backward compatibility
   */
  handleSubmit(): void
  registerNextEnabledContribution: (from: NextEnabledContributor, nextEnabled: boolean) => void
  nextEnabled: boolean
  serverValidationIssue: SubscriptionStepValidationIssue | null
  goBackToPreviousStep: () => void
  goBackToBreadcrumbRootStep: (breadcrumb: SubscriptionBreadcrumb) => void
  goBackToStepRoot: () => void
  /**
   * Identifies a details being zoomed on, such as a guranatee table
   */
  detail?: SubscriptionDetail
  isLoadingWhileTryCompleteStep?: boolean
  synchronizing: boolean
  context: 'backoffice' | 'selfonboarding'
  /**
   * Allow ui elements to update the subscription owner, intended for backoffice use only
   */
  setSubscriptionOwner: (customerId: string) => void
  changes: SubscriptionDimensionnedState
  selectedProductName?: string
  backofficeStepTitle?: string
  backofficeStepSubtitle?: string
  organization?: OrganizationFunnelInfo
}

/**
 * Props that are common across all subscription steps (doesn't depend on the step)
 */
export type CommonSubscriptionStepProps = CommonSubscriptionProps & {
  localState: LooselyTypedData | undefined
  updateAndPersistLocalChanges: (changes: LooselyTypedData) => void
  updateLocalChanges: (changes: LooselyTypedData) => void
}

export type SubscriptionElementBlockProps<UiElement extends SubscriptionUiElement = SubscriptionUiElement> =
  CommonSubscriptionProps & {
    stateProxy: StateProxy | undefined
    uiElement: UiElement
  }

export type StateProxy = {
  readRequired<TYPE>(dimension: AbstractDimension<string, TYPE>): TYPE
  read<TYPE>(dimension: AbstractDimension<string, TYPE>): TYPE | undefined
  write<TYPE>(dimension: AbstractDimension<string, TYPE>, value: TYPE | null | undefined): void
  writePermanently<TYPE>(dimension: AbstractDimension<string, TYPE>, value: TYPE | null | undefined): void
  useWrite<TYPE>(dimension: AbstractDimension<string, TYPE>): (value: TYPE | null | undefined) => void
}

type CreateStateProxyProps = {
  localState: LooselyTypedData | undefined
  updateLocalChanges: (changes: LooselyTypedData) => void
  updateAndPersistLocalChanges: (changes: LooselyTypedData) => void
  allowedDimensions: readonly AbstractDimension[]
}

export function createStateProxy({
  localState,
  updateLocalChanges,
  updateAndPersistLocalChanges,
  allowedDimensions,
}: CreateStateProxyProps): StateProxy | undefined {
  if (!localState) return undefined

  function readRequired<TYPE>(dimension: AbstractDimension<string, TYPE>): TYPE {
    return checkDefinedAndNotNull(read(dimension), { name: dimension.name })
  }
  function read<TYPE>(dimension: AbstractDimension<string, TYPE>): TYPE | undefined {
    checkDimension(dimension)
    return checkDefinedAndNotNull(localState, { name: dimension.name })[dimension.name] as TYPE | undefined
  }
  function write<TYPE>(dimension: AbstractDimension<string, TYPE>, value: TYPE | null | undefined): void {
    checkDimension(dimension)
    updateLocalChanges({ [dimension.name]: value ?? null })
  }
  function writePermanently<TYPE>(dimension: AbstractDimension<string, TYPE>, value: TYPE | null | undefined): void {
    checkDimension(dimension)
    updateAndPersistLocalChanges({ [dimension.name]: value ?? null })
  }
  function useWrite<TYPE>(dimension: AbstractDimension<string, TYPE>): (value: TYPE | null | undefined) => void {
    checkDimension(dimension)
    return useCallback(
      (value: TYPE | null | undefined) => {
        updateLocalChanges({ [dimension.name]: value ?? null })
      },
      [dimension.name],
    )
  }
  function checkDimension(dimension: AbstractDimension<string>): void {
    if (!allowedDimensions.includes(dimension)) {
      throw new TechnicalError('Invalid stateProxy use', { context: { dimension: dimension.name } })
    }
  }

  return { readRequired, read, write, useWrite, writePermanently }
}

function useStateProxy(
  localState: LooselyTypedData | undefined,
  updateLocalChanges: (changes: LooselyTypedData) => void,
  updateAndPersistLocalChanges: (changes: LooselyTypedData) => void,
  allowedDimensions: readonly AbstractDimension[],
): StateProxy | undefined {
  return useMemo(() => {
    if (!localState) return undefined

    return createStateProxy({ localState, updateLocalChanges, updateAndPersistLocalChanges, allowedDimensions })
  }, [allowedDimensions, localState, updateAndPersistLocalChanges, updateLocalChanges])
}

function getSelectedProduct(state: LooselyTypedData | undefined): string | undefined {
  if (!state) return undefined
  if (state['rcdaSelected']) return 'RC Décennale'
  if (state['rcphSelected']) return 'RC Professionnelle'
  if (state['mrpwSelected']) return 'Multirisque'
  return undefined
}

export function useSubscriptionElementProps<UiElement extends SubscriptionUiElement>(
  stepProps: CommonSubscriptionStepProps & { uiElement: UiElement },
): SubscriptionElementBlockProps<UiElement> {
  const { localState, updateLocalChanges, uiElement, updateAndPersistLocalChanges, ...passedProps } = stepProps
  const allowedDimensions = getElementDimensions(uiElement)
  const stateProxy = useStateProxy(localState, updateLocalChanges, updateAndPersistLocalChanges, allowedDimensions)

  const [organization] = trpcReact.organizations.getOrganizationInfoBySubscriptionId.useSuspenseQuery(
    passedProps.subscriptionId,
  )

  return { ...passedProps, stateProxy, uiElement, selectedProductName: getSelectedProduct(localState), organization }
}
