import { mutaActualCosts, mutaEmpty, mutaOneHundredPct, mutaYes, type MutaGuarantees } from '../muta-guarantees.js'

export const mutaEss1Guarantees: MutaGuarantees = {
  HO_FDS_CO: mutaActualCosts,
  HO_FDS_NCO: mutaOneHundredPct,
  HO_HO: {
    type: 'optamThreeYears',
    PA: {
      FY: mutaOneHundredPct,
      SY: mutaOneHundredPct,
      TY: { type: 'percentage', percentage: 125 },
    },
    PNA: mutaOneHundredPct,
  },
  HO_HO_HO: mutaOneHundredPct,
  HO_CH_FY: mutaEmpty,
  HO_CH_SY: mutaEmpty,
  HO_CH_TY: mutaEmpty,
  HO_SA: mutaEmpty,
  HO_FJ: mutaActualCosts,
  HO_CO_HO: mutaEmpty,
  HO_HAD: mutaOneHundredPct,
  MD_MN: mutaEmpty,
  MD_PREV: mutaOneHundredPct,
  MD_PROT: mutaOneHundredPct,
  SC_HM_MG: {
    type: 'optam',
    PA: mutaOneHundredPct,
    PNA: mutaOneHundredPct,
  },
  SC_HM_MS: {
    type: 'optam',
    PA: mutaOneHundredPct,
    PNA: mutaOneHundredPct,
  },
  SC_HM_IM: {
    type: 'optam',
    PA: mutaOneHundredPct,
    PNA: mutaOneHundredPct,
  },
  SC_HM_ATM: {
    type: 'optam',
    PA: mutaOneHundredPct,
    PNA: mutaOneHundredPct,
  },
  SC_HP: mutaOneHundredPct,
  SC_LAB: mutaOneHundredPct,
  SC_TRANS: mutaOneHundredPct,
  SC_MEDIC_PEC: mutaOneHundredPct,
  SC_PAPP: mutaEmpty,
  SC_MEDIC_NPEC: mutaEmpty,
  DEN_HPS: mutaOneHundredPct,
  DEN_NPC_RB: mutaEmpty,
  DEN_ORT_RB_FY: mutaOneHundredPct,
  DEN_ORT_RB_SY: mutaOneHundredPct,
  DEN_ORT_RB_TY: mutaOneHundredPct,
  DEN_PROT_RB_FY: mutaOneHundredPct,
  DEN_PROT_RB_SY: mutaOneHundredPct,
  DEN_PROT_RB_TY: mutaOneHundredPct,
  DEN_PLAF_REMB_FY: mutaEmpty,
  DEN_PLAF_REMB_SY: mutaEmpty,
  DEN_PLAF_REMB_TY: mutaEmpty,
  OPT_SIMP_MONT_FY: mutaEmpty,
  OPT_SIMP_MONT_SY: mutaEmpty,
  OPT_SIMP_MONT_TY: mutaEmpty,
  OPT_COMP_MONT_FY: mutaEmpty,
  OPT_COMP_MONT_SY: mutaEmpty,
  OPT_COMP_MONT_TY: mutaEmpty,
  OPT_T_COMP_MONT_FY: mutaEmpty,
  OPT_T_COMP_MONT_SY: mutaEmpty,
  OPT_T_COMP_MONT_TY: mutaEmpty,
  OPT_SIMP_COMP_FY: mutaEmpty,
  OPT_SIMP_COMP_SY: mutaEmpty,
  OPT_SIMP_COMP_TY: mutaEmpty,
  OPT_SIMP_T_COMP_FY: mutaEmpty,
  OPT_SIMP_T_COMP_SY: mutaEmpty,
  OPT_SIMP_T_COMP_TY: mutaEmpty,
  OPT_COMP_T_COMP_FY: mutaEmpty,
  OPT_COMP_T_COMP_SY: mutaEmpty,
  OPT_COMP_T_COMP_TY: mutaEmpty,
  OPT_FORF_LENT: mutaOneHundredPct,
  AUD_APP_PROT: mutaOneHundredPct,
  AUD_FORF_AUD: mutaEmpty,
  AUD_ACCESS: mutaOneHundredPct,
  CUR_HO_CONV: mutaEmpty,
  CUR_HO_HCONV: mutaEmpty,
  CUR_FRAIS_ETAB: mutaEmpty,
  CUR_TRANS_HEB: mutaEmpty,
  SER_TIERS_PAY: mutaYes,
  SER_ASSIST: mutaYes,
  SER_AUTO_REMB: mutaYes,
  ASS_TELE_CONSULT_MEDI: { type: 'sessionPerInsured', session: 10 },
  ASS_COACH_WELLNESS: { type: 'session', session: 4 },
  ASS_ACCOMP_PSY: { type: 'session', session: 4 },
  ASS_HOME_HELP_FROM: {
    type: 'amount',
    amount: 150,
  },
  ASS_HOME_HELP_TO: {
    type: 'amount',
    amount: 500,
  },
  ASS_TRANS_TAXI_FROM: {
    type: 'amount',
    amount: 150,
  },
  ASS_TRANS_TAXI_TO: {
    type: 'amount',
    amount: 500,
  },
}
