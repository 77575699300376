import type { TemporaryAPIProblem } from '@orus.eu/backend/src/lib/temporary-api-failure'
import type { DraftAgreedTermsInputIncompleteProblem } from '@orus.eu/backend/src/services/subscription/subscription-service'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager'
import {
  downloadFileFromBuffer,
  TemporaryProblemError,
  useCrash,
  useEnqueueToast,
  useLanguage,
  useTranslate,
} from '@orus.eu/pharaoh'
import { isFailure, type Result } from '@orus.eu/result'
import { useParams } from '@tanstack/react-router'
import { useCallback, useState } from 'react'
import { trpc } from '../../client'
import { dynamicFileNames } from '../download-filenames'
import { usePermissions } from '../use-permissions'

export type UseDownloadAgreedTermsProps = {
  subscriptionId: string
  changes: SubscriptionDimensionnedState
  email: string | undefined
  endorsementId?: string
}

/**
 * Download the agreed terms draft with last changes
 * */
export function useDownloadAgreedTerms({
  changes,
  email,
  subscriptionId,
  endorsementId,
}: UseDownloadAgreedTermsProps): {
  download: () => Promise<void>
  currentEdgeCase: 'input-incomplete' | undefined
  downloadInProgress: boolean
} {
  const translate = useTranslate()
  const language = useLanguage()
  const crash = useCrash()
  const { type: userType } = usePermissions()
  const { organization } = useParams({ strict: false })
  const { enqueueToast } = useEnqueueToast()
  const [currentEdgeCase, setCurrentEdgeCase] = useState<'input-incomplete' | undefined>(undefined)
  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

  const downloadAgreedTerms = useCallback(async () => {
    setDownloadInProgress(true)
    setCurrentEdgeCase(undefined)
    let result: Result<Buffer, TemporaryAPIProblem | DraftAgreedTermsInputIncompleteProblem>

    if (endorsementId) {
      result = await trpc.endorsement.generateAgreedTermsDraft.mutate({
        subscriptionId,
        changes,
        endorsementId,
      })
    } else if (userType === 'partner' && !!organization) {
      result = await trpc.pendingSubscriptions.partnerGenerateAgreedTermsDraftV2.mutate({
        subscriptionId,
        changes,
        organization,
      })
    } else {
      result = await trpc.pendingSubscriptions.generateAgreedTermsDraftV2.mutate({
        subscriptionId,
        changes,
      })
    }
    if (isFailure(result)) {
      setDownloadInProgress(false)
      switch (result.problem.type) {
        case 'temporary-api-failure':
          crash(new TemporaryProblemError())
          return
        case 'draft-agreed-terms-input-incomplete':
          setCurrentEdgeCase('input-incomplete')
          return
      }
    }

    const fileName = dynamicFileNames['agreed-terms-draft'](email, language)

    try {
      await downloadFileFromBuffer(result.output, fileName)
    } catch (err) {
      console.error('Failed to download file', err)
      enqueueToast('Échec du téléchargement du fichier.', { variant: 'danger' })
    } finally {
      setDownloadInProgress(false)
    }

    enqueueToast(translate('download_successful'), { variant: 'success' })
  }, [userType, organization, endorsementId, email, language, enqueueToast, translate, subscriptionId, changes, crash])

  return { download: downloadAgreedTerms, currentEdgeCase, downloadInProgress }
}
