import { css } from '@emotion/react'
import { type Amount, type FinancialRate } from '@orus.eu/amount'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager'
import { type PaymentRecurrence, type ProductAttributes } from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { Dialog, spacing, Text, useDialogVisibility, type ScreenType } from '@orus.eu/pharaoh'
import { PricingBlock, PricingDetailsDialog } from '@orus.eu/pharaoh/src/components/features/quote-v3'
import { memo, useCallback, useEffect, useMemo } from 'react'
import { useDownloadAgreedTerms } from '../../../lib/hooks/use-download-agreed-terms'
import { getExplanationsAboveSubscriptionButton } from './explanations-above-subscription-button'

type QuotePricingBlockAndDialogProps = {
  variant: ScreenType
  productsAttributes: ProductAttributes[]
  monthlyPrice: Amount
  yearlyPrice: Amount
  firstPeriodPrice: Amount | undefined
  partnerApplicationPrice?: Amount
  yearlyTerrorismTaxPrice?: Amount
  monthlyWithoutCommitment: boolean
  paymentRecurrence: PaymentRecurrence
  forbiddenMonthlyPayment: boolean
  commitment: 'monthly' | 'yearly'
  discount: FinancialRate
  yearlyCommitmentHasDiscount: boolean
  onPaymentRecurrenceChange: (paymentRecurrence: PaymentRecurrence) => void
  onSubscribeClick?: () => void
  className?: string
  changes: SubscriptionDimensionnedState
  email: string | undefined
  subscriptionId: string
  operatingZone: OperatingZone
}

export const QuotePricingBlockAndDialog = memo<QuotePricingBlockAndDialogProps>(
  function QuotePricingBlockAndDialog(props) {
    const {
      variant,
      productsAttributes,
      monthlyPrice,
      yearlyPrice,
      firstPeriodPrice,
      paymentRecurrence,
      forbiddenMonthlyPayment,
      discount,
      yearlyCommitmentHasDiscount,
      monthlyWithoutCommitment,
      yearlyTerrorismTaxPrice,
      onSubscribeClick,
      onPaymentRecurrenceChange,
      className,
      commitment,
      changes,
      email,
      partnerApplicationPrice,
      subscriptionId,
      operatingZone,
    } = props

    const dialogVisibility = useDialogVisibility('pricing-details')

    const explanationsAboveSubscriptionButton = useMemo(
      () => getExplanationsAboveSubscriptionButton(productsAttributes),
      [productsAttributes],
    )

    const {
      download: downloadAgreedTerms,
      currentEdgeCase,
      downloadInProgress,
    } = useDownloadAgreedTerms({
      subscriptionId,
      changes,
      email,
    })

    const handleDownloadClick = useCallback(() => {
      downloadAgreedTerms().catch((err) => {
        console.error('Unhandled error during download', err)
      })
    }, [downloadAgreedTerms])

    const { show: showDialog, hide: hideDialog, visible: isDialogVisible } = useDialogVisibility('cp-input-incomplete')

    useEffect(() => {
      hideDialog()
      if (currentEdgeCase === 'input-incomplete') {
        showDialog()
      }
    }, [showDialog, currentEdgeCase, hideDialog])

    return (
      <>
        {dialogVisibility.visible ? (
          <PricingDetailsDialog
            commitment={commitment}
            paymentRecurrence={paymentRecurrence}
            forbiddenMonthlyPayment={forbiddenMonthlyPayment}
            monthlyPrice={monthlyPrice}
            yearlyPrice={yearlyPrice}
            firstPeriodPrice={firstPeriodPrice}
            productsAttributes={productsAttributes}
            onClose={dialogVisibility.hide}
            yearlyCommitmentHasDiscount={yearlyCommitmentHasDiscount}
            discount={discount}
            yearlyTerrorismTaxPrice={yearlyTerrorismTaxPrice}
            partnerApplicationPrice={partnerApplicationPrice}
            onPaymentRecurrenceChange={!forbiddenMonthlyPayment ? onPaymentRecurrenceChange : undefined}
            operatingZone={operatingZone}
          />
        ) : (
          <></>
        )}
        <PricingBlock
          className={className}
          variant={variant}
          paymentRecurrence={paymentRecurrence}
          forbiddenMonthlyPayment={forbiddenMonthlyPayment}
          monthlyPrice={monthlyPrice}
          yearlyPrice={yearlyPrice}
          explanationsAboveSubscriptionButton={explanationsAboveSubscriptionButton}
          yearlyCommitmentHasDiscount={yearlyCommitmentHasDiscount}
          monthlyWithoutCommitment={monthlyWithoutCommitment}
          discount={discount}
          productsAttributes={productsAttributes}
          onSubscribeClick={onSubscribeClick}
          onSeeMoreDetailsClick={dialogVisibility.show}
          handleDownload={handleDownloadClick}
          downloadInProgress={downloadInProgress}
        />
        {isDialogVisible ? (
          <Dialog
            onClose={hideDialog}
            size="medium"
            title="Devis actualisé"
            onPrimaryAction={() => document.location.reload()}
            primaryActionLabel="Actualiser"
          >
            <Text
              variant="body1"
              css={css`
                margin-bottom: ${spacing[50]};
              `}
            >
              Suite à une modification de votre devis, nous avons besoin de plus d&apos;informations.
            </Text>
            <Text variant="body1">Actualisez la page pour continuer puis télécharger votre devis.</Text>
          </Dialog>
        ) : null}
      </>
    )
  },
)
