import { amountToNumber } from '@orus.eu/amount'
import { isEsRcphDeductible, type QuotePageBodySubscriptionUiElement } from '@orus.eu/dimensions'
import { DropdownWithDescription, useTranslate, valuesToLabels, valuesToStrings } from '@orus.eu/pharaoh'
import { memo, useCallback, useMemo } from 'react'
import { sendMessage } from '../../../../../../lib/tracking/tracking'
import type { StateProxy } from '../../../subscription-v2-props'

export type EsRcphDeductibleSelectorProps = {
  uiElement: QuotePageBodySubscriptionUiElement
  stateProxy: StateProxy
  subscriptionId: string
}
export const EsRcphDeductibleSelector = memo<EsRcphDeductibleSelectorProps>(function EsRcphDeductibleSelector({
  uiElement,
  stateProxy,
  subscriptionId,
}) {
  const translate = useTranslate()
  const value = stateProxy.readRequired(uiElement.dimensions.esRcphSelectedDeductible)
  const allowedValues = stateProxy.readRequired(uiElement.dimensions.esRcphAllowedDeductibles)

  const valueString = value == undefined ? null : value.toString()
  const handleChange = useCallback(
    (valueString?: string | null) => {
      if (!valueString) return
      const value = parseInt(valueString)
      if (!isEsRcphDeductible(value)) return
      stateProxy.write(uiElement.dimensions.esRcphSelectedDeductible, value)
      const quote = stateProxy.readRequired(uiElement.dimensions.hiddenQuote)
      const yearlyTotalPremium = quote ? amountToNumber(quote.yearlyTotalPremium) : null
      sendMessage({
        subscription_id: subscriptionId,
        event: 'subscription_updated_front',
        mrph_deductible: null,
        es_rcph_deductible: value,
        yearly_total_premium: yearlyTotalPremium,
        rcda_deductible: null,
        option: null,
        rcda_history_takeover_years: null,
        rcph_loi: null,
        es_rcph_loi: null,
        mrph_option_pe: null,
      })
    },
    [stateProxy, subscriptionId, uiElement],
  )

  const { deductibleStrings, deductibleLabels } = useMemo(() => {
    const deductibleStrings = valuesToStrings(allowedValues)
    const deductibleLabels = valuesToLabels(allowedValues)
    return { deductibleStrings, deductibleLabels }
  }, [allowedValues])

  if (allowedValues.length < 2) return <></>

  return (
    <DropdownWithDescription
      title={translate('deductible')}
      description={translate('deductible_definition')}
      value={valueString}
      values={deductibleStrings}
      labels={deductibleLabels}
      onChange={handleChange}
      aria-label={translate('deductible')}
      size="large"
    />
  )
})
