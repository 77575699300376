import styled from '@emotion/styled'
import { Dialog, FlexColumn, spacing, Text } from '@orus.eu/pharaoh'
import { memo } from 'react'

type ActivitiesDetailDialogProps = {
  activities: {
    activity: string
    displayName: string
  }[]
  onClose: () => void
}

export const ActivitiesDetailDialog = memo<ActivitiesDetailDialogProps>(function ComplementaryActivitiesDialog({
  activities,
  onClose,
}) {
  return (
    <Dialog blurOverlay size="medium" title={'Vos activités supplémentaires'} onClose={onClose}>
      <FlexColumn gap={spacing[50]} margin={`0 ${spacing[60]}`}>
        {activities.map((a, index) => (
          <ComplementaryActivityLabel key={index} variant="body2">
            {a.displayName}
          </ComplementaryActivityLabel>
        ))}
      </FlexColumn>
    </Dialog>
  )
})

const ComplementaryActivityLabel = styled(Text)`
  margin-top: ${spacing[30]};
  display: list-item;
  flex-wrap: wrap;
`
