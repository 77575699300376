import type { EmptyStateQuoteUiElement } from '@orus.eu/dimensions'
import { EmptyState } from '@orus.eu/pharaoh'
import { ifStateProxy } from '../if-state-proxy'

export const EmptyStateQuoteUiElementBlock = ifStateProxy<EmptyStateQuoteUiElement>(
  function EmptyStateQuoteUiElementBlock() {
    return (
      <EmptyState
        icon="folder-light"
        subtitle="Sélectionnez une activité et un produit"
        secondaryText="d'assurance pour obtenir une offre."
      />
    )
  },
)
