import { type FinancialRate } from '@orus.eu/amount'
import type { SubscriptionDimensionnedState } from '@orus.eu/backend/src/views/subscriptions/subscription-state-manager'
import {
  getCummulatedMonthlyTotalPremiumPerYear,
  getYearlyTotalPremiumSplitPerMonth,
  type AggregatedQuote,
  type ProductAttributes,
  type QuoteOfferInformation,
} from '@orus.eu/dimensions'
import type { OperatingZone } from '@orus.eu/operating-zone'
import { useScreenType } from '@orus.eu/pharaoh'
import { memo, useMemo } from 'react'
import { QuotePricingBlockAndDialog } from '../../organisms/quote-pricing-block-and-dialog'

type PricingBlockProps = {
  paymentRecurrence: 'monthly' | 'yearly'
  forbiddenMonthlyPayment: boolean
  commitment: 'monthly' | 'yearly'
  yearlyCommitmentHasDiscount: boolean
  quote: AggregatedQuote
  email: string | undefined
  offerInformation: QuoteOfferInformation
  discount: FinancialRate
  onSubscribeClick?: () => void
  onPaymentRecurrenceChange: (paymentRecurrence: 'monthly' | 'yearly') => void
  className?: string
  subscriptionId: string
  changes: SubscriptionDimensionnedState
  operatingZone: OperatingZone
}

export const PricingBlock = memo<PricingBlockProps>(function PricingBlock(props) {
  const screenType = useScreenType()
  const {
    paymentRecurrence,
    forbiddenMonthlyPayment,
    commitment,
    yearlyCommitmentHasDiscount,
    quote,
    email,
    offerInformation,
    discount,
    onSubscribeClick,
    onPaymentRecurrenceChange,
    className,
    subscriptionId,
    changes,
    operatingZone,
  } = props

  const monthlyPrice =
    paymentRecurrence === 'monthly' ? quote.subsequentMonthsTotalPremium : getYearlyTotalPremiumSplitPerMonth(quote)
  const yearlyPrice =
    paymentRecurrence === 'yearly' ? quote.yearlyTotalPremium : getCummulatedMonthlyTotalPremiumPerYear(quote)

  const productAttributes = useMemo<ProductAttributes[]>(
    () => offerInformation.products.map(({ name, attributes }) => ({ productName: name, attributes })),
    [offerInformation.products],
  )

  return (
    <QuotePricingBlockAndDialog
      variant={screenType}
      productsAttributes={productAttributes}
      monthlyPrice={monthlyPrice}
      yearlyPrice={yearlyPrice}
      firstPeriodPrice={quote.firstPaymentAmount}
      commitment={commitment}
      paymentRecurrence={paymentRecurrence}
      forbiddenMonthlyPayment={forbiddenMonthlyPayment}
      onPaymentRecurrenceChange={onPaymentRecurrenceChange}
      onSubscribeClick={onSubscribeClick}
      discount={discount}
      yearlyCommitmentHasDiscount={yearlyCommitmentHasDiscount}
      monthlyWithoutCommitment={commitment === 'monthly'}
      yearlyTerrorismTaxPrice={quote.terrorismTax}
      className={className}
      changes={changes}
      email={email}
      subscriptionId={subscriptionId}
      partnerApplicationPrice={quote.partnerApplicationPremium}
      operatingZone={operatingZone}
    />
  )
})
