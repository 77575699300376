import { PARIS } from '@orus.eu/calendar-date'
import { DateTime } from 'luxon'

/**
 * Describes a point in time in a human readable way, in the form of the time elapsed since that point in time.
 * Examples : "5 minutes ago", "10 hours ago", "il y a 1 minute"
 */
export const units: Intl.RelativeTimeFormatUnit[] = ['year', 'month', 'week', 'day', 'hour', 'minute', 'second']
export const timeAgo = (date: number): string => {
  const dateTime = DateTime.fromMillis(date, { zone: PARIS })
  const diff = dateTime.diffNow().shiftTo(...units)
  const unit = units.find((unit) => diff.get(unit) !== 0) || 'second'

  const relativeFormatter = new Intl.RelativeTimeFormat('fr', {
    numeric: 'auto',
  })
  return relativeFormatter.format(Math.trunc(diff.as(unit)), unit)
}
