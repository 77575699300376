import {
  customCoverDimension,
  customMrpActivityAddressDimension,
  customMrpActivityDimension,
  customMrpSummaryDimension,
  customMutaSummaryDimension,
  customRcdaActivityDimension,
  customRcdaSummaryDimension,
  customRcphActivityDimension,
  customRcphSummaryDimension,
  customSignatureDimension,
  esRcphCustomActivityDimension,
  esRcphCustomSummaryDimension,
  productSelectedDimension,
} from '@orus.eu/dimensions'
import type { StateProxy } from '../../subscription-v2-props'

export const customClausesDimensions = [
  customCoverDimension,
  customRcphActivityDimension,
  customSignatureDimension,
  customRcphSummaryDimension,
  customMrpActivityAddressDimension,
  customMrpSummaryDimension,
  customMrpActivityDimension,
  customMutaSummaryDimension,
  customRcdaSummaryDimension,
  customRcdaActivityDimension,
  esRcphCustomActivityDimension,
  esRcphCustomSummaryDimension,
] as const

export type CustomClausesDimensions = (typeof customClausesDimensions)[number]

export function getNumberOfCustomClauses(stateProxy: StateProxy): number {
  let count = 0
  for (const dimension of getCustomClausesDimensionsBySelectedProduct(stateProxy)) {
    if (stateProxy.read(dimension)) count++
  }
  return count
}

export function getCustomClausesDimensionsBySelectedProduct(stateProxy: StateProxy): CustomClausesDimensions[] {
  const clausesDimensions: CustomClausesDimensions[] = [customCoverDimension, customSignatureDimension]

  for (const [product, selectedDimension] of Object.entries(productSelectedDimension)) {
    const selectedProduct = !!stateProxy.read(selectedDimension)
    const typedProduct = product as keyof typeof productSelectedDimension
    if (selectedProduct) {
      switch (typedProduct) {
        case 'mrph':
        case 'mrpw':
          {
            clausesDimensions.push(
              customMrpActivityAddressDimension,
              customMrpSummaryDimension,
              customMrpActivityDimension,
            )
          }
          break
        case 'muta':
          {
            clausesDimensions.push(customMutaSummaryDimension)
          }
          break
        case 'rcda':
          {
            clausesDimensions.push(customRcdaSummaryDimension, customRcdaActivityDimension)
          }
          break
        case 'rcph':
          {
            clausesDimensions.push(customRcphActivityDimension, customRcphSummaryDimension)
          }
          break
        case 'es-rcph':
          {
            clausesDimensions.push(esRcphCustomActivityDimension, esRcphCustomSummaryDimension)
          }
          break
      }
    }
  }

  return clausesDimensions
}
