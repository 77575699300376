import type { OperatingZone } from '@orus.eu/operating-zone'
import type { HelpContactDetails } from '@orus.eu/pharaoh/src/components/features/quote-v3/help-block'
import type { OrganizationFunnelInfo } from '@orus.eu/right-access-management'
import { helpContactDetails } from '../../../../lib/help-contact-details'

export function getSupportContactDetails(
  organization: OrganizationFunnelInfo | undefined,
  operatingZone: OperatingZone,
): { isOrusContact: boolean; helpContactDetails: HelpContactDetails } {
  if (!organization || organization.technicalName === 'orus' || organization.hasEmbeddedFunnel) {
    return { isOrusContact: true, helpContactDetails: helpContactDetails[operatingZone] }
  }

  return {
    isOrusContact: false,
    helpContactDetails: {
      email: organization.email,
      phone: organization.phone,
    },
  }
}
